import { cls } from '@react/utils/classname'
import { isMobile } from '@site/js/utils/breakpoint'
import { pxToRem } from '@site/js/utils/px-to-rem'
import { useEffect } from 'react'

import AnchorNavigationElement from './AnchorNavigationElement'
import { getLocationHash, navigationHeight, updateLocationHash } from './AnchorNavigationHelperMethods'
import { useAnchorNavigation } from './hooks'

type PageTitle = {
  id: string
  class: string
  label: string
  isActive: boolean
}

type AnchorNavigationProps = {
  anchornavigationarialabel: string
  anchornavigationalignment?: string
}

const header: HTMLElement = document.querySelector('.cmp-header')
const headerNavigation: HTMLElement = document.querySelector('.cmp-header__navigation')
const headerMobileHomeLink: HTMLElement = document.querySelector('.cmp-header__home-link')

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function AnchorNavigation(props: AnchorNavigationProps) {
  const {
    setTemporaryPageTitles,
    domPageTitles,
    locationHash,
    pageTitles,
    setPageTitles,
    isScrollingProgrammatically,
    isOverflowing,
    setIsOverflowing,
    setIsMobileLandscape,
    stickyNavigationTopPosition,
    setStickyNavigationTopPosition,
    stickyNavigationContainerTopPosition,
    setStickyNavigationContainerTopPosition,
    isNavigationExpanded,
    setIsNavigationExpanded,
    anchorNavigationActiveItemLabel,
    setActiveLink,
    getTitlesAboveFold,
    anchorNavigationContainer,
    anchorNavigationNavbar,
    anchorNavigationList,
  } = useAnchorNavigation(props)

  useEffect(() => {
    const startTime = new Date().getTime()
    const interval = setInterval(() => {
      if (new Date().getTime() - startTime > 10000) {
        clearInterval(interval)
      }
      setTemporaryPageTitles(
        Array.from(document.querySelectorAll('h2.cmp-title__text, h2.cmp-title-v2__title, div.cmp-text')).filter(
          title => !title.hasAttribute('data-anchor-hide') && title.id,
        ),
      )
    }, 1000)
  }, [])

  useEffect(() => {
    setTemporaryPageTitles(
      Array.from(document.querySelectorAll('h2.cmp-title__text, h2.cmp-title-v2__title, div.cmp-text')).filter(
        title => !title.hasAttribute('data-anchor-hide') && title.id,
      ),
    )
  }, [])

  useEffect(() => {
    updateLocationHash(locationHash)
  }, [locationHash])

  useEffect(() => {
    if (domPageTitles?.length) {
      setPageTitles(
        (domPageTitles as HTMLElement[]).map(domPageTitle => {
          let label = ''

          if (domPageTitle.classList.contains('cmp-text') && !domPageTitle.hasAttribute('data-anchor-label')) {
            const h2Element = domPageTitle.querySelector('h2')

            if (h2Element) {
              label = h2Element.innerText.trim()
            } else {
              label = 'Untitled'
            }
          } else {
            label = domPageTitle.getAttribute('data-anchor-label') || domPageTitle.innerText.trim() || 'Untitled'
          }
          return {
            id: domPageTitle.id,
            class: domPageTitle.className,
            label: label,
            isActive: false,
          }
        }),
      )
    }
  }, [domPageTitles])

  useEffect(() => {
    setIsOverflowing(anchorNavigationList?.current?.clientWidth < anchorNavigationList?.current?.scrollWidth)
    setIsMobileLandscape(window.innerWidth > window.innerHeight && isMobile)

    const scrollEventHandler = () => {
      const anchorNavigationTopPosition =
        anchorNavigationNavbar?.current?.getBoundingClientRect().top - navigationHeight(headerNavigation.offsetHeight, header.offsetHeight)
      setStickyNavigationTopPosition(window.scrollY <= anchorNavigationTopPosition || window.scrollY <= 0)
      if (!isScrollingProgrammatically) {
        const titlesAboveFold = getTitlesAboveFold()
        const activeLinkParams = titlesAboveFold?.length ? { id: titlesAboveFold.at(-1).id } : {}
        setActiveLink(activeLinkParams)
      }
    }

    window.addEventListener('scroll', scrollEventHandler)

    return () => window.removeEventListener('scroll', scrollEventHandler)
  }, [pageTitles, isScrollingProgrammatically])

  useEffect(() => {
    const initialLocalHash = getLocationHash()
    if (initialLocalHash) {
      const cleanLocationHash = initialLocalHash.slice(1)
      setActiveLink({ id: cleanLocationHash, isLoadedWithHash: true })
    } else {
      const titlesAboveFold = getTitlesAboveFold()
      const activeLinkParams = titlesAboveFold?.length ? { id: titlesAboveFold.at(-1).id } : {}
      setActiveLink(activeLinkParams)
    }
  }, [pageTitles])

  useEffect(() => {
    setStickyNavigationContainerTopPosition(
      stickyNavigationTopPosition
        ? null
        : pxToRem(navigationHeight(headerNavigation.offsetHeight, header.offsetHeight, headerMobileHomeLink.offsetHeight)),
    )
  }, [stickyNavigationTopPosition, headerNavigation.offsetHeight, header.offsetHeight, headerMobileHomeLink.offsetHeight])

  return (
    pageTitles && (
      <div
        ref={anchorNavigationContainer}
        className={cls({
          'cmp-anchor-navigation': true,
          'cmp-anchor-navigation--sticky': !stickyNavigationTopPosition,
        })}
        style={stickyNavigationContainerTopPosition ? { top: `${stickyNavigationContainerTopPosition}` } : {}}
        data-dm="anchornavigation.component"
        data-cmp-is="anchorNavigation"
      >
        <nav
          ref={anchorNavigationNavbar}
          aria-label={props.anchornavigationarialabel}
          className={cls({
            'cmp-anchor-navigation__nav': true,
            'cmp-anchor-navigation__expanded': isNavigationExpanded,
          })}
        >
          <span
            className={cls({
              'cmp-anchor-navigation__toggle-span': true,
              'cmp-anchor-navigation__toggle-span--is-hidden': isNavigationExpanded,
            })}
            onClick={() => setIsNavigationExpanded(!isNavigationExpanded)}
          >
            {anchorNavigationActiveItemLabel}
          </span>
          <label htmlFor="cmp-anchor-navigation__mobile" className="cmp-anchor-navigation__toggle"></label>
          <input
            id="cmp-anchor-navigation__mobile"
            onChange={e => setIsNavigationExpanded(e.target.checked)}
            checked={isNavigationExpanded}
            type="checkbox"
          />
          <ul
            ref={anchorNavigationList}
            className={cls({
              'cmp-anchor-navigation__list': true,
              'cmp-anchor-navigation__list-centered': props.anchornavigationalignment === 'cmp-anchor-navigation__list-centered',
              'cmp-anchor-navigation__item--overflowed': isOverflowing,
            })}
          >
            {pageTitles?.map((pageTitle: PageTitle, index: number) => {
              return (
                <AnchorNavigationElement
                  key={pageTitle.id}
                  index={index}
                  id={pageTitle.id}
                  isActive={pageTitle.isActive}
                  label={pageTitle.label}
                  setActiveLink={setActiveLink}
                  expanded={isNavigationExpanded}
                />
              )
            })}
          </ul>
        </nav>
      </div>
    )
  )
}
