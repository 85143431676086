import { isMobile } from '@site/js/utils/breakpoint'
import { useRef,useEffect,useState } from 'react'

import { elementTopPosition, getLocationHash } from '../AnchorNavigationHelperMethods'

let scrollingProgrammatically = null
const foldRatio = 0.2

type TitleWithLabel = {
  id: string
  label: string
  isActive: boolean
  element: HTMLElement
}

export const useAnchorNavigation = props => {
  const [domPageTitles, setDomPageTitles] = useState(null)
  const [temporaryPageTitles, setTemporaryPageTitles] = useState(null)
  const [pageTitles, setPageTitles] = useState(null)
  const [isNavigationExpanded, setIsNavigationExpanded] = useState(false)
  const [isScrollingProgrammatically, setIsScrollingProgrammatically] = useState(false)
  const [stickyNavigationTopPosition, setStickyNavigationTopPosition] = useState(true)
  const [stickyNavigationContainerTopPosition, setStickyNavigationContainerTopPosition] = useState(null)
  const [isMobileLandscape, setIsMobileLandscape] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [locationHash, setLocationHash] = useState(location.hash)
  const [anchorNavigationActiveItemLabel, setAnchorNavigationActiveItemLabel] = useState(props.anchornavigationlabel || '')
  const anchorNavigationContainer = useRef(null)
  const anchorNavigationNavbar = useRef(null)
  const anchorNavigationList = useRef(null)

  // eslint-disable-next-line sonarjs/cognitive-complexity

  useEffect(() => {
    const DATA_ANCHOR_HIDE = 'data-anchor-hide'

    const isLabelVisible = (element: HTMLElement): boolean => {
      if (element.classList.contains('cmp-text')) {
        return element.hasAttribute(DATA_ANCHOR_HIDE)
      }
      return !element.hasAttribute(DATA_ANCHOR_HIDE)
    }

    const getLabel = (element: HTMLElement): string => {
      let label = 'Untitled'

      const getH2Label = (h2Element: HTMLElement): string => {
        return h2Element ? h2Element.innerText.trim() : ''
      }

      const setH2Id = (h2Element: HTMLElement, element: HTMLElement): void => {
        if (!h2Element.id && element.id) {
          h2Element.id = element.id
        }
      }

      if (element.classList.contains('cmp-text')) {
        if (element.hasAttribute(DATA_ANCHOR_HIDE)) {
          const h2Element = element.querySelector('h2')
          if (h2Element) {
            setH2Id(h2Element, element)
            label = getH2Label(h2Element)
          }
        } else {
          label = element.getAttribute('data-anchor-label') || element.innerText.trim() || label
        }
      } else {
        const h2Element = element.querySelector('h2')
        if (h2Element) {
          label = getH2Label(h2Element)
        }
      }

      return label
    }

    const updateTitles = () => {
      const titles = Array.from(document.querySelectorAll('h2.cmp-title__text, h2.cmp-title-v2__title, div.cmp-text'))
        .filter(element => isLabelVisible(element as HTMLElement))
        .map(element => {
          const label = getLabel(element as HTMLElement)
          const titleElement = element as HTMLElement

          return {
            id: titleElement.id || titleElement.querySelector('h2')?.id || '',
            label: label,
            isActive: false,
            element: titleElement,
          } as TitleWithLabel
        })

      setDomPageTitles(titles.map(title => title.element as HTMLElement))
    }

    updateTitles()
    const observer = new MutationObserver(updateTitles)
    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (domPageTitles?.length) {
      setPageTitles(
        domPageTitles.map(title => ({
          id: title.id,
          label: title.label,
          isActive: false,
        })),
      )
    }
  }, [domPageTitles])

  const setActiveLink = ({ id = null, isClicked = false, isLoadedWithHash = false } = {}) => {
    if (pageTitles) {
      let activeItemLabel = pageTitles[0]?.label
      if (isClicked || isLoadedWithHash) {
        setIsScrollingProgrammatically(true)
        clearTimeout(scrollingProgrammatically)
        scrollingProgrammatically = setTimeout(() => {
          setIsScrollingProgrammatically(false)
        }, 2000)
      }

      domPageTitles?.forEach((domPageTitle, index) => {
        const idToUse = domPageTitle.id || domPageTitle.querySelector('h2')?.id
        if (idToUse === id) {
          activeItemLabel = pageTitles[index].label
          pageTitles[index].isActive = true
        } else {
          pageTitles[index].isActive = false
        }

        if ((isClicked || isLoadedWithHash) && idToUse === id) {
          const elementPosition = domPageTitle.getBoundingClientRect().top
          const offsetPosition = elementPosition + window.scrollY - calculateBufferHeight()
          window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
        }
      })

      if (id) {
        if (`#${id}` !== getLocationHash()) {
          setLocationHash(`#${id}`)
        }
      } else {
        setLocationHash('')
      }
      setAnchorNavigationActiveItemLabel(activeItemLabel)
    }
  }

  const calculateBufferHeight = () => {
    if (isMobile) {
      return 100
    }
    return stickyNavigationTopPosition ? 220 : 100
  }

  const getTitlesAboveFold = (): HTMLElement[] => {
    if (!domPageTitles || domPageTitles.length === 0) {
      return []
    }
    const foldPosition = Math.floor((isMobileLandscape ? window.innerWidth : window.innerHeight) * foldRatio)
    return (domPageTitles as HTMLElement[])?.filter(title => {
      const titleTopPosition = elementTopPosition(title) - foldPosition
      return window.scrollY >= titleTopPosition
    })
  }

  return {
    temporaryPageTitles,
    setTemporaryPageTitles,
    domPageTitles,
    setDomPageTitles,
    locationHash,
    pageTitles,
    setPageTitles,
    isScrollingProgrammatically,
    isOverflowing,
    setIsOverflowing,
    setIsMobileLandscape,
    stickyNavigationTopPosition,
    setStickyNavigationTopPosition,
    stickyNavigationContainerTopPosition,
    setStickyNavigationContainerTopPosition,
    isNavigationExpanded,
    setIsNavigationExpanded,
    anchorNavigationActiveItemLabel,
    setActiveLink,
    getTitlesAboveFold,
    anchorNavigationContainer,
    anchorNavigationNavbar,
    anchorNavigationList,
  }
}
